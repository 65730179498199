import { get } from 'lodash-es';

import { PROFILE_MODULE_NAME } from '~coreModules/profile/js/profile-store';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import { AB_TESTING_MODULE_NAME } from '~coreModules/ab-testing/js/ab-testing-store';

import {
    APPLICATION_CHANNELS_MAP,
    NEXT,
    FORWARD,
    BACKWARD,
} from '~coreModules/core/js/constants';
import { PRODUCT_FILTER_TYPES, SELECT_CONTENT_TYPES } from '~coreModules/analytics/js/analytics-constants';
import { convertCentsToDollars } from '~coreModules/core/js/currency-utils';
import { trackEvent, trackPageview } from '~coreModules/analytics/js/analytics-utils';

export const ANALYTICS_MODULE_NAME = 'analytics';

export const SET_FEEDBACK_SOURCE = 'SET_FEEDBACK_SOURCE';

export const TRACK_PAGEVIEW = 'TRACK_PAGEVIEW';
export const TRACK_LOGIN_COMPLETED = 'TRACK_LOGIN_COMPLETED';
export const TRACK_LOGOUT = 'TRACK_LOGOUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const TRIGGER_PAGEVIEW = 'TRIGGER_PAGEVIEW';
export const TRACK_ACCORDION_OPENED = 'TRACK_ACCORDION_OPENED';
export const TRACK_CAROUSEL_SCROLL = 'TRACK_CAROUSEL_SCROLL';
export const TRACK_CATALOG_FILTER_UPDATED = 'TRACK_CATALOG_FILTER_UPDATED';
export const TRACK_CATALOG_SORT_UPDATED = 'TRACK_CATALOG_SORT_UPDATED';
export const TRACK_CHECKOUT_START = 'TRACK_CHECKOUT_START';
export const TRACK_CONTENTFUL_CONTENT_CLICKED = 'TRACK_CONTENTFUL_CONTENT_CLICKED';
export const TRACK_CONTENT_CARD_CLICKED = 'TRACK_CONTENT_CARD_CLICKED';
export const TRACK_CONTENT_CARD_SCROLL = 'TRACK_CONTENT_CARD_SCROLL';
export const TRACK_EMAIL_SUBSCRIBE = 'TRACK_EMAIL_SUBSCRIBE';
export const TRACK_ERROR = 'TRACK_ERROR';
export const TRACK_FEEDBACK_COMPLETED = 'TRACK_FEEDBACK_COMPLETED';
export const TRACK_FEEDBACK_STARTED = 'TRACK_FEEDBACK_STARTED';
export const TRACK_LOGIN_STARTED = 'TRACK_LOGIN_STARTED';
export const TRIGGER_LOGIN_COMPLETED = 'TRIGGER_LOGIN_COMPLETED';
export const TRACK_NAVIGATION_CLICKED = 'TRACK_NAVIGATION_CLICKED';
export const TRACK_NOTIFICATION_CLICKED = 'TRACK_NOTIFICATION_CLICKED';
export const TRACK_NOTIFICATION_DISMISSED = 'TRACK_NOTIFICATION_DISMISSED';
export const TRACK_NOTIFICATION_VIEWED = 'TRACK_NOTIFICATION_VIEWED';
export const TRACK_SMS_SUBSCRIBED = 'TRACK_SMS_SUBSCRIBED';
export const TRACK_PAGINATION_CLICK = 'TRACK_PAGINATION_CLICK';
export const TRACK_PRODUCT_CLICK = 'TRACK_PRODUCT_CLICK';
export const TRACK_PRODUCT_FILTER_UPDATED = 'TRACK_PRODUCT_FILTER_UPDATED';
export const TRACK_PRODUCT_SAVED_CHANGE = 'TRACK_PRODUCT_SAVED_CHANGE';
export const TRACK_PURCHASE_SUCCESS = 'TRACK_PURCHASE_SUCCESS';
export const TRACK_SEARCH = 'TRACK_SEARCH';
export const TRACK_SEARCH_REDIRECT = 'TRACK_SEARCH_REDIRECT';
export const TRACK_SEARCH_STARTED = 'TRACK_SEARCH_STARTED';
export const TRACK_VIEW_ALL_CLICK = 'TRACK_VIEW_ALL_CLICK';
export const TRACK_VIEW_CONTENT_CARD = 'TRACK_VIEW_CONTENT_CARD';
export const TRACK_VIEW_PRODUCT = 'TRACK_VIEW_PRODUCT';
export const TRACK_VIEW_PRODUCT_LIST = 'TRACK_VIEW_PRODUCT_LIST';
export const TRACK_IN_GRID_CONTENT_CLICKED = 'TRACK_IN_GRID_CONTENT_CLICKED';

export const UPDATE_ANALYTICS_TRACKING_PREFERENCE = 'UPDATE_ANALYTICS_TRACKING_PREFERENCE';
export const TRACK_FOOTER_CLICKED = 'TRACK_FOOTER_CLICKED';

export const getUserData = (rootState) => {
    const { trackingOptIn } = rootState[COMPLIANCE_MODULE_NAME];
    const { profileId, employee } = rootState[PROFILE_MODULE_NAME];
    const channelId = APPLICATION_CHANNELS_MAP[rootState.siteBusinessType];
    const { cookieClientId: clientId, locationId } = rootState;

    const userData = {
        channelId,
        trackingOptIn,
        clientId,
        locationId,
    };

    if (profileId) {
        Object.assign(userData, { profileId, employee });
    }

    return userData;
};

export default function createAnalytics(config) {
    return {
        namespaced: true,

        state: {
            app: {
                channel: 'web',
                siteId: 'nu-us',
                version: config.version,
                currency: config.defaultCurrency,
                environment: config.environment,
                language: config.defaultLanguage,
            },
            page: {},
            user: {
                trackingOptIn: null,
            },
            abTests: [],
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [TRACK_PAGEVIEW](state, { pageInfo, userData = {}, abTests = [], appData = {}, business }) {
                const prevPage = state.page;

                let pageCategory = '(not set)';
                if (get(pageInfo, 'currentPage.meta.analytics.pageCategory')) {
                    pageCategory = get(pageInfo, 'currentPage.meta.analytics.pageCategory');
                } else if (pageInfo.parents?.length) {
                    pageInfo.parents.forEach((parent) => {
                        pageCategory = get(parent, 'meta.analytics.pageCategory', pageCategory);
                    });
                }

                let pageType = '(not set)';
                if (get(pageInfo, 'currentPage.meta.analytics.pageType')) {
                    pageType = get(pageInfo, 'currentPage.meta.analytics.pageType');
                } else if (pageInfo.parents?.length) {
                    pageInfo.parents.forEach((parent) => {
                        pageType = get(parent, 'meta.analytics.pageType', pageType);
                    });
                }

                const pagePath = get(pageInfo, 'currentPage.path', '');

                state.app.business = business;

                if (!state.user.entryUrl) {
                    state.user.entryUrl = pagePath;
                }

                state.user = {
                    ...state.user,
                    ...userData,
                };

                if (Object.keys(prevPage).length) {
                    state.page.prevPagePath = prevPage.path;
                    state.page.prevPageCategory = prevPage.pageCategory;
                }

                state.page = {
                    ...state.page,
                    path: pagePath,
                    pageCategory,
                    pageType,
                };

                let pageData = {};
                if (pageInfo.pageData) {
                    pageData = { ...pageInfo.pageData };
                }

                state.abTests = abTests.map(abTest => ({
                    id: abTest.testId,
                    variant: abTest.testGroup,
                }));

                state.app = {
                    ...state.app,
                    ...appData,
                };

                if (!get(pageInfo, 'currentPage.meta.analytics.noTrack', false)) {
                    trackPageview(state, pageData);
                }
            },
            [TRACK_LOGIN_COMPLETED](state, { label, userData }) {
                state.user = userData;
                trackEvent(state, { action: 'login-completed', label });
                trackEvent(state, { action: 'login', type: label }, false);
            },
            [TRACK_LOGOUT](state) {
                state.user = {};
                trackEvent(state, { action: 'logout' });
            },
            [UPDATE_USER_DATA](state, { userData, action, label, catalogResponseId }) {
                state.user = userData;
                trackEvent(state, { action, label, catalogResponseId });
            },
            [SET_FEEDBACK_SOURCE](state, source) {
                state.feedbackSource = source;
            },
        },

        /* eslint-disable no-param-reassign */
        actions: {
            [TRIGGER_PAGEVIEW]({ commit, getters, rootState, rootGetters }, pageInfo) {
                const userData = getters.userData(rootState);
                const abTests = get(rootState[AB_TESTING_MODULE_NAME], 'tests');
                const { isMobileWebview } = rootGetters;
                const appData = {};

                if (isMobileWebview) {
                    appData.channel = 'ios';
                }

                commit(TRACK_PAGEVIEW, { pageInfo, userData, abTests, appData, business: rootState.siteBusinessType });
            },
            [TRACK_ACCORDION_OPENED]({ state }, { label, isGA4, accordionTitle }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'view_accordion',
                        accordionTitle,
                    }, false);
                } else {
                    trackEvent(state, { action: 'accordion-opened', label, contentModuleId: label }, false);
                }
            },
            [TRACK_LOGIN_STARTED]({ state }, { label }) {
                trackEvent(state, { action: 'login-started', label: state.page.path }, false);
                trackEvent(state, { action: 'login_begin', type: label }, false);
            },
            [TRIGGER_LOGIN_COMPLETED]({ commit, getters, rootState }, { label }) {
                const userData = getters.userData(rootState);
                commit(TRACK_LOGIN_COMPLETED, { label, userData });
            },
            [TRACK_CAROUSEL_SCROLL]({ state }, {
                label,
                analyticsType = null,
                value,
                contentModuleId,
                catalogResponseId,
            }) {
                trackEvent(state, {
                    action: 'carousel_scroll',
                    type: analyticsType,
                    scrollDirection: label === NEXT ? FORWARD : BACKWARD,
                    index: value,
                    contentModuleId,
                    catalogResponseId,
                }, false);
            },
            [TRACK_CATALOG_FILTER_UPDATED]({ dispatch, state }, { label, catalogResponseId }) {
                trackEvent(state, {
                    action: 'product-filter-updated',
                    label,
                    catalogResponseId,
                }, false);

                dispatch(TRACK_PRODUCT_FILTER_UPDATED, {
                    type: PRODUCT_FILTER_TYPES.FILTER,
                    filterValue: label,
                });
            },
            [TRACK_CATALOG_SORT_UPDATED]({ dispatch, state }, { label, catalogResponseId }) {
                trackEvent(state, {
                    action: 'product-sort-updated',
                    label,
                    catalogResponseId,
                }, false);

                dispatch(TRACK_PRODUCT_FILTER_UPDATED, {
                    type: PRODUCT_FILTER_TYPES.SORT,
                    filterValue: label,
                });
            },
            [TRACK_CHECKOUT_START]({ state }, checkoutData) {
                trackEvent(state, {
                    action: 'begin_checkout',
                    ...checkoutData,
                }, false);
            },
            [TRACK_PURCHASE_SUCCESS]({ state }, purchaseData) {
                trackEvent(state, {
                    action: 'purchase',
                    ...purchaseData,
                    tax: convertCentsToDollars(purchaseData.tax),
                    total: convertCentsToDollars(purchaseData.total),
                    products: purchaseData.products.map(product => ({
                        ...product,
                        productPrice: convertCentsToDollars(product.productPrice),
                    })),
                }, false);
            },
            [TRACK_CONTENTFUL_CONTENT_CLICKED]({ state }, {
                destination,
                contentModuleId,
                contentTitle,
            }) {

                // this is a UA event
                trackEvent(state, {
                    action: 'content-click',
                    label: destination,
                    contentModuleId,
                }, false);

                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.CONTENT,
                    contentModuleId,
                    destination,
                    contentTitle,
                }, false);
            },
            [TRACK_CONTENT_CARD_CLICKED]({ state }, { contentCardType, contentCardSlug, destination }) {
                trackEvent(state, {
                    action: 'select_content_card',
                    contentCardType,
                    contentCardSlug,
                    destination,
                }, false);
            },
            [TRACK_CONTENT_CARD_SCROLL]({ state }, { direction, ...eventData }) {
                trackEvent(state, {
                    action: 'content_card_scroll',
                    scrollDirection: direction === NEXT ? FORWARD : BACKWARD,
                    ...eventData,
                }, false);
            },
            [TRACK_EMAIL_SUBSCRIBE]({ state }, { label, isGA4, optInLocation, traceId }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'generate_lead',
                        optInLocation,
                        traceId,
                    }, false);
                } else {
                    trackEvent(state, { action: 'anonymous-email-subscribe', label }, false);
                }
            },
            [TRACK_ERROR]({ state }, errorSlug) {
                if (errorSlug) {
                    // UA event
                    trackEvent(state, { action: 'error', label: errorSlug, nonInteraction: true }, false);
                    // GA4 event
                    trackEvent(state, { action: 'service_error', type: errorSlug, nonInteraction: true }, false);
                }
            },
            [TRACK_FEEDBACK_COMPLETED]({ state }, { label, isGA4, product, type }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'feedback_completed',
                        type,
                    }, false);
                } else {
                    trackEvent(state, {
                        category: state.feedbackSource,
                        action: 'feedback-completed',
                        label,
                        product,
                    }, false);
                }
            },
            [TRACK_FEEDBACK_STARTED]({ state, commit }, { label, isGA4, product, type }) {
                const source = state.page.prevPageCategory || state.page.pageCategory;
                commit(SET_FEEDBACK_SOURCE, source);

                trackEvent(state, {
                    category: source,
                    action: 'feedback-started',
                    label,
                    product,
                }, false);

                if (isGA4) {
                    trackEvent(state, {
                        action: 'feedback_started',
                        type,
                    }, false);
                }
            },
            [TRACK_FOOTER_CLICKED]({ state }, { label }) {
                trackEvent(state, { action: 'footer-click', label }, false);

                trackEvent(state, {
                    action: 'select_footer_link',
                    destination: label,
                }, false);
            },
            [TRACK_IN_GRID_CONTENT_CLICKED]({ state }, {
                contentModuleId,
                contentTitle,
                destination,
                index,
            }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.CONTENT,
                    contentModuleId,
                    contentTitle,
                    destination,
                    index,
                }, false);
            },
            [TRACK_NAVIGATION_CLICKED]({ state }, {
                isGA4,
                label,
                navAction,
                navName,
                navDestination,
            }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'select_nav',
                        navAction,
                        navName,
                        navDestination,
                    }, false);
                } else {
                    trackEvent(state, { action: 'navigation-click', label }, false);
                }
            },
            [TRACK_NOTIFICATION_CLICKED]({ state }, { label, isGA4, notificationType, notificationSlug, destination }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'select_notification',
                        notificationType,
                        notificationSlug,
                        destination,
                    }, false);
                } else {
                    trackEvent(state, { action: 'notification-clicked', label }, false);
                }
            },
            [TRACK_NOTIFICATION_DISMISSED]({ state }, {
                label,
                isGA4,
                notificationType,
                notificationSlug,
                destination,
            }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'select_notification',
                        notificationType,
                        notificationSlug,
                        destination,
                    }, false);
                } else {
                    trackEvent(state, { action: 'notification-dismissed', label, nonInteraction: true }, false);
                }
            },
            [TRACK_NOTIFICATION_VIEWED]({ state }, { label, isGA4, notificationType, notificationSlug }) {
                if (isGA4) {
                    trackEvent(state, {
                        action: 'view_notification',
                        notificationType,
                        notificationSlug,
                    }, false);
                } else {
                    trackEvent(state, { action: 'notification-viewed', label, nonInteraction: true }, false);
                }
            },
            [TRACK_SMS_SUBSCRIBED]({ state }, { optInLocation, action }) {
                trackEvent(state, { action, optInLocation }, false);
            },
            [TRACK_PAGINATION_CLICK]({ state }, {
                action,
                label,
                value,
                contentModuleId,
                catalogResponseId,
            }) {
                trackEvent(state, {
                    action,
                    label,
                    value,
                    contentModuleId,
                    catalogResponseId,
                }, false);
            },
            [TRACK_PRODUCT_CLICK]({ state }, { isGA4 = false, product, productList, eventData }) {
                if (isGA4) {
                    trackEvent(state, { action: 'select_item', product, productList }, false);
                } else {
                    trackEvent(state, { action: 'product-click', ...eventData }, false);
                }
            },
            [TRACK_PRODUCT_FILTER_UPDATED]({ state }, eventData) {
                trackEvent(state, {
                    action: 'add_product_filter',
                    ...eventData,
                }, false);
            },
            [TRACK_PRODUCT_SAVED_CHANGE]({ state }, { newStatus, ...eventData }) {
                trackEvent(state, {
                    action: newStatus ? 'add_to_wishlist' : 'remove_from_wishlist',
                    ...eventData,
                }, false);
            },
            [TRACK_SEARCH]({ state }, { isGA4, ...eventInfo }) {
                if (isGA4) {
                    const {
                        numberOfResults,
                        searchTerms,
                        searchIndex,
                        searchRedirect,
                    } = eventInfo;

                    trackEvent(state, {
                        action: 'search',
                        searchTerms,
                        searchIndex,
                        searchRedirect,
                        ...(!searchRedirect ? {
                            numberOfResults,
                        } : {}),
                    }, false);
                } else {
                    const { label, value } = eventInfo;

                    trackEvent(state, {
                        category: 'search',
                        action: 'search-product',
                        label,
                        value,
                    }, false);
                }
            },
            [TRACK_SEARCH_REDIRECT]({ state }, { label }) {
                trackEvent(state, { action: 'search-redirect', label }, false);
            },
            [TRACK_SEARCH_STARTED]({ state }, { label }) {
                trackEvent(state, { action: 'search-started', label }, false);
            },
            [TRACK_VIEW_ALL_CLICK]({ state }, eventInfo) {
                // this is a UA event
                const { productTrayId } = eventInfo;
                trackEvent(state, { action: 'rec-view-all', productTrayId }, false);

                const {
                    destination,
                    contentModuleId,
                    contentTitle,
                } = eventInfo;

                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.PRODUCT_TRAY_LINK,
                    destination,
                    contentModuleId,
                    contentTitle,
                }, false);
            },
            [TRACK_VIEW_CONTENT_CARD]({ state }, { contentCardType, contentCardSlug }) {
                trackEvent(state, { action: 'view_content_card', contentCardType, contentCardSlug }, false);
            },
            [TRACK_VIEW_PRODUCT]({ state }, { product, productList, traceId }) {
                trackEvent(state, { action: 'view_item', product, productList, traceId }, false);
            },
            [TRACK_VIEW_PRODUCT_LIST]({ state }, { productList, product, products, traceId }) {
                trackEvent(state, {
                    action: 'view_item_list',
                    ...(products ? { products } : { product }),
                    productList: {
                        ...productList,
                        offset: productList?.offset ? productList.offset.toString() : '0',
                    },
                    traceId,
                }, false);
            },
            [UPDATE_ANALYTICS_TRACKING_PREFERENCE]({ commit, getters, rootState }) {
                const userData = getters.userData(rootState);
                // UA event
                commit(UPDATE_USER_DATA, {
                    userData,
                    action: 'tracking-preference-updated',
                });
                // GA4 event
                commit(UPDATE_USER_DATA, {
                    userData,
                    action: 'updated_tracking_preferences',
                });
            },
        },

        getters: {
            userData() {
                return rootState => getUserData(rootState);
            },
        },
    };
}
