/* istanbul ignore next */
import createBaseRouter from '~coreModules/core/js/router';

import { commonRouteData } from '~modules/core/js/router-constants';

// Expose a factory function to ensure a new router per request
export default function createRouter(store, runtimeConfig) {
    const router = createBaseRouter(store, {
        routes: commonRouteData,
        runtimeConfig,
    });

    return router;
}
