import createStore, { APP_LOGOUT_ACTIONS } from '~coreModules/core/js/store';

import createNavigationModule, { NAVIGATION_MODULE_NAME } from '~modules/nav/js/nav-store';
import createProfileModule, { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';
import createSiteSettingsModule, { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

// Expose a factory function to ensure a new store per request
export default function createCommonStore(initialState, logger, config) {
    const plugins = [];

    const rootState = {};

    const rootActions = {
        // eslint-disable-next-line no-empty-pattern
        async [APP_LOGOUT_ACTIONS]({ }) { await null; },
    };

    /* eslint-disable no-param-reassign */
    const rootMutations = {};
    /* eslint-enable no-param-reassign */

    const rootGetters = {};

    const modules = {
        [NAVIGATION_MODULE_NAME]: createNavigationModule(logger, config),
        [PROFILE_MODULE_NAME]: createProfileModule(),
        [SITE_SETTINGS_MODULE_NAME]: createSiteSettingsModule(logger, config),
    };

    const store = createStore({
        initialState,
        logger,
        config,
        plugins,
        rootState,
        rootActions,
        rootMutations,
        rootGetters,
        modules,
    });

    return store;
}
