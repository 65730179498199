import { inject } from 'vue';
import { isFinite } from 'lodash-es';

import { convertCentsToDollars } from '~coreModules/core/js/currency-utils';

export const filtersInjectionKey = Symbol('filters');

/**
 * A composable that provides access to the custom filters
 * @function
 * @returns {Object} the injected custom filters object made available to the application
 */
export const useFilters = () => inject(filtersInjectionKey);

/**
 * Provides custom filters to the application
 * @param {Object} app - the application instance
 * @param {Object} filters - the custom filters object to be provided
 * @returns {Object} the provided custom filters
 */
export function provideCustomVueFilters(app, filters) {
    // provide the filters to the app so they can be used in script setup
    app.provide(filtersInjectionKey, filters);
    return filters;
}

/* eslint-disable no-param-reassign */
export default function attachCustomVueFilters(app, store, i18n, logger) {
    /**
     * Converts cents to i18n-formatted dollars
     * @param {Number} cents - amount of cents to convert
     * @param {Object} [options={ returnZero: true }] - options object for the conversion
     * @param {Boolean} [options.returnZero=true] - whether to return an internationalized string for 0 dollars
     * @returns {String} i18n-formatted string
     */
    function i18nFormatCents(cents, { returnZero = true } = {}) {
        const { currency } = store.state.siteSettings;
        const dollars = convertCentsToDollars(cents, logger);
        return returnZero || dollars ? i18n.global.n(dollars, currency) : '';
    }

    /**
     * Converts dollars to i18n-formatted string
     * @param {Number} dollars - amount of dollars to convert
     * @returns {String} i18n-formatted string
     */
    function i18nFormatDollars(dollars) {
        const { currency } = store.state.siteSettings;
        const safeDollars = parseFloat(dollars);

        if (!isFinite(safeDollars)) {
            logger.error('Error rendering formatted dollars, invalid amount');
            return '';
        }

        return i18n.global.n(safeDollars, currency);
    }

    /**
     * Converts dollars to i18n-formatted string and removes cents
     * @param {Number} dollars - amount of dollars to convert
     * @returns {String} i18n-formatted string
     */
    function i18nFormatDollarsNoCents(dollars) {
        const safeDollars = parseInt(dollars, 10);

        if (!isFinite(safeDollars)) {
            logger.error('Error rendering formatted dollars with no cents, invalid amount');
            return '';
        }

        return i18n.global.n(safeDollars, 'currencyNoCents');
    }

    /**
     * Converts cents to i18n-formatted string and removes cents if present
     * @param {Number} cents - amount of cents to convert
     * @returns {String} i18n-formatted string
     */
    function i18nFormatWithCentsWhenNotZero(cents) {
        if (Number.isNaN(cents)) {
            logger.error('Error rendering formatted cents when not zero, invalid amount');
            return '';
        }

        const dollars = convertCentsToDollars(cents, logger);

        if (Number.isInteger(dollars)) {
            return i18nFormatDollarsNoCents(dollars);
        }

        return i18nFormatCents(cents);
    }

    // in template use $filters.i18nFormatCents instead of vue 2 | currency-format-cents filters
    app.config.globalProperties.$filters = {
        i18nFormatCents,
        i18nFormatDollars,
        i18nFormatDollarsNoCents,
        i18nFormatWithCentsWhenNotZero,
    };

    // in setup() use useFilters().i18nFormatCents instead of options api this.$filters.i18nFormatCents
    provideCustomVueFilters(app, app.config.globalProperties.$filters);
}
