import { merge } from 'lodash-es';

import createNavigation from '~coreModules/nav/js/nav-store';

import { buildNavigationTreeForSlug } from '~coreModules/nav/js/nav-utils';

import { APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';
import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';
import { HEADER_NAV_ROOT_SLUG } from '~coreModules/nav/js/nav-constants';

export { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';

export const RENT_APP_BASE_URL = APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.rental];

export default function createCommonNavigation(logger, config) {
    return merge(createNavigation(logger, config), {
        getters: {
            /* hierarchical footer navigation items */
            footerNav(state, getters) {
                return getters.getFooterNav(RENT_APP_BASE_URL);
            },
            /* hierarchical header navigation items */
            headerNav(state) {
                const { flatNavigation } = state;

                return buildNavigationTreeForSlug({
                    slug: HEADER_NAV_ROOT_SLUG,
                    flatNavigation,
                    logger,
                    appBaseUrl: RENT_APP_BASE_URL,
                });
            },
        },
    });
}
