import { createLogger, createStore } from 'vuex';
import { get } from 'lodash';

import createActionLogger from '~coreModules/core/js/action-logger';
import analyticsActionListener from '~coreModules/analytics/js/analytics-listener';

import { NUU_COOKIE_CLIENT_ID } from '~coreModules/core/js/es5/constants';
import { NUU_COOKIE_MOBILE_WEBVIEW } from '~coreModules/core/js/cookie-constants';
import { APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';
import { CONTENT_TYPES } from '~coreModules/contentful/js/contentful-constants';

import { getInternalLinkAttributes } from '~coreModules/core/js/url-utils';
import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';

import abTestingModule, { AB_TESTING_MODULE_NAME } from '~coreModules/ab-testing/js/ab-testing-store';
import browserModule, { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';
import complianceModule, { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import contentCardsModule, { CONTENT_CARDS_MODULE_NAME } from '~coreModules/content-cards/js/content-cards-store';
import discoverModule, { DISCOVER_MODULE_NAME } from '~coreModules/discover/js/discover-store';
import headerModule, { HEADER_MODULE_NAME } from '~coreModules/header/js/header-store';
import modalsModule, { MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';
import toastsModule, { TOASTS_MODULE_NAME } from '~coreModules/toasts/js/toasts-store';
import anonymousNotificationsModule,
{ ANONYMOUS_NOTIFICATIONS_MODULE_NAME } from '~coreModules/notifications/js/anonymous-notifications-store';

export const APP_LOGOUT_ACTIONS = 'APP_LOGOUT_ACTIONS';
export const GLOBAL_EVENT = 'GLOBAL_EVENT';
export const FETCH_ERROR_CONTENT = 'FETCH_ERROR_CONTENT';
export const UNIVERSAL_REDIRECT = 'UNIVERSAL_REDIRECT';

export const CLEAR_ROUTING_ERROR = 'CLEAR_ROUTING_ERROR';
export const CANCEL_NAVIGATION = 'CANCEL_NAVIGATION';
export const SET_ERROR_CONTENT = 'SET_ERROR_CONTENT';
export const SET_404_ERROR = 'SET_404_ERROR';
export const SET_500_ERROR = 'SET_500_ERROR';
export const SET_ROUTE_IS_LOADING = 'SET_ROUTE_IS_LOADING';
export const SET_SITE_BUSINESS_TYPE = 'SET_SITE_BUSINESS_TYPE';
export const SET_ROUTE_IS_AUTO_SCROLLING = 'SET_ROUTE_IS_AUTO_SCROLLING';
export const CHECK_IF_MOBILE_WEBVIEW = 'CHECK_IF_MOBILE_WEBVIEW';
export const SET_IS_MOBILE_WEBVIEW = 'SET_IS_MOBILE_WEBVIEW';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_AUTHENTICATED_FROM_COOKIE = 'SET_AUTHENTICATED_FROM_COOKIE';
export const SET_COOKIE_CLIENT_ID = 'SET_COOKIE_CLIENT_ID';
export const SET_LOCATION_DC_ID = 'SET_LOCATION_DC_ID';
export const SET_LOGIN_REDIRECT_URL = 'SET_LOGIN_REDIRECT_URL';

// TODO: these can be adjusted as needed
const STATE_SIZE_THRESHOLD_WARN = 25000;
const STATE_SIZE_THRESHOLD_ERROR = 75000;

// Expose a factory function to ensure a new store per request
export default function createStoreInstance({
    initialState,
    logger,
    config,
    plugins,
    rootState,
    rootActions,
    rootMutations,
    rootGetters,
    modules,
}) {
    let strict = false;

    const storeState = {
        ...rootState,
        siteBusinessType: '',
        routingError: null,
        isAuthenticated: false,
        routeIsLoading: true,
        routeIsAutoScrolling: false, // used to reflect the state of Vue-router's scrolling behavior on route enter
        isMobileWebview: false,
        cookieClientId: '',
        locationId: '',
        errorContent: null,
        loginRedirectUrl: '',
    };

    const storeActions = {
        // noop for app specific logout shutdown actions
        // eslint-disable-next-line no-empty-pattern, no-unused-vars
        async [APP_LOGOUT_ACTIONS]({ }) { await null; },
        ...rootActions,
        // Required to have an empty action to register it for use
        // Used to trigger analytics events that are ingested by subscribeAction listeners via plugin
        [GLOBAL_EVENT]() {},
        [FETCH_ERROR_CONTENT]({ commit, state }, { slug }) {
            const contentType = state.routingError?.contentSlotErrorSlug ?
                CONTENT_TYPES.CONTENT_SLOT : CONTENT_TYPES.CONTENT_PAGE;

            return getContentfulPageContentForSlug({
                contentfulSvc: this.$contentfulSvc,
                contentType,
                slug: state.routingError?.contentSlotErrorSlug || slug,
                isTimed: false,
            })
                .then((res) => {
                    const shapedContent = shapeContentfulContentPageResponse(res);
                    commit(SET_ERROR_CONTENT, shapedContent);
                })
                .catch((error) => {
                    logger.debugError('Failed to fetch the not-found-page content: ', error);
                    return Promise.reject(error);
                });
        },
        // Perform a redirect on either client or server, to be used in
        // fetchData or beforeRouteEnter - prior to routing to the initial destination route
        [UNIVERSAL_REDIRECT]({ getters }, { router, route, replace = true }) {
            let to = route;
            let isExternal = false;

            /* for client-side redirects, we can simply use vue router to replace route */
            if (process.env.VUE_ENV === 'client') {
                if (typeof route === 'string') {
                    ({ to, isExternal } = getInternalLinkAttributes(route, getters.appBaseUrl));
                }

                if (isExternal) {
                    window.location.href = to;
                    return true;
                }

                if (replace) {
                    return router.replace(to);
                }

                return router.push(to);
            }

            if (typeof route === 'object') {
                to = router.resolve(to)?.fullPath;
            }

            return Promise.reject({
                redirect: true,
                status: 302,
                url: to,
            });
        },
    };

    /* eslint-disable no-param-reassign */
    const storeMutations = {
        ...rootMutations,
        [CLEAR_ROUTING_ERROR](state) {
            state.routingError = null;
        },
        [CANCEL_NAVIGATION](state) {
            state.routingError = null;
            state.routeIsLoading = false;
        },
        [SET_ERROR_CONTENT](state, content) {
            state.errorContent = content;
        },
        [SET_404_ERROR](state, { message, contentSlotErrorSlug = '' }) {
            const errorMsg = typeof message === 'string' ? message : get(message, 'data.message', 'unknown error');

            state.routingError = {
                code: 404,
                message: errorMsg,
                contentSlotErrorSlug,
            };
        },
        [SET_500_ERROR](state, message) {
            const errorMsg = typeof message === 'string' ? message : get(message, 'data.message', 'unknown error');

            state.routingError = {
                code: 500,
                message: errorMsg,
            };
        },
        [SET_SITE_BUSINESS_TYPE](state, type) {
            state.siteBusinessType = type;
        },
        [SET_ROUTE_IS_LOADING](state, isLoading) {
            state.routeIsLoading = isLoading;
        },
        [SET_ROUTE_IS_AUTO_SCROLLING](state, isScrolling) {
            state.routeIsAutoScrolling = isScrolling;
        },
        [SET_AUTHENTICATED](state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        [SET_COOKIE_CLIENT_ID](state, cookies) {
            state.cookieClientId = cookies[NUU_COOKIE_CLIENT_ID] || '';
        },
        [SET_LOCATION_DC_ID](state, locationDcHeader) {
            state.locationId = locationDcHeader || '';
        },
        [SET_AUTHENTICATED_FROM_COOKIE](state, cookies) {
            if (cookies[config.authCookieName]) {
                state.isAuthenticated = true;
            }
        },
        [SET_IS_MOBILE_WEBVIEW](state, cookies) {
            if (cookies[NUU_COOKIE_MOBILE_WEBVIEW]) {
                state.isMobileWebview = true;
            }
        },
        [SET_LOGIN_REDIRECT_URL](state, loginRedirectUrl) {
            state.loginRedirectUrl = loginRedirectUrl;
        },
    };
    /* eslint-enable no-param-reassign */

    const storeGetters = {
        ...rootGetters,
        loggedIn(state) {
            return state.isAuthenticated;
        },
        isMobileWebview(state) {
            return state.isMobileWebview;
        },
        appBaseUrl(state) {
            return APP_BASE_URLS_MAP[state.siteBusinessType];
        },
        phoneLoginRedirectPath(state, getters) {
            const { appBaseUrl } = getters;
            return !appBaseUrl || appBaseUrl === '/' ? '/' : `${appBaseUrl}/login/phone`;
        },
    };

    const storeModules = {
        ...modules,
        [AB_TESTING_MODULE_NAME]: abTestingModule,
        [BROWSER_MODULE_NAME]: browserModule,
        [COMPLIANCE_MODULE_NAME]: complianceModule,
        [CONTENT_CARDS_MODULE_NAME]: contentCardsModule,
        [DISCOVER_MODULE_NAME]: discoverModule,
        [HEADER_MODULE_NAME]: headerModule,
        [MODALS_MODULE_NAME]: modalsModule,
        [TOASTS_MODULE_NAME]: toastsModule,
        [ANONYMOUS_NOTIFICATIONS_MODULE_NAME]: anonymousNotificationsModule,
    };

    // Ignore code coverage for dev-only store modifications
    /* istanbul ignore next  */
    if (config.logLevel === 'debug' && process.env.NODE_ENV !== 'production') {
        // Enable strict mode only in dev environments
        strict = true;

        // Only include expensive debug logging in dev environments
        plugins.push(createActionLogger({
            // Don't deep-clone the state, takes far too long as the state grows
            logState: false,
        }));

        plugins.push(createLogger({
            collapsed: true,
            transformer(s) {
                if (process.env.VUE_ENV === 'server') {
                    // Transform the state before logging it on the server
                    /* eslint-disable no-param-reassign */
                    s = '{ ... }';
                    /* eslint-enable no-param-reassign */
                }
                return s;
            },
            mutationTransformer(mutation) {
                if (process.env.VUE_ENV === 'server') {
                    return mutation.type;
                }
                return mutation;
            },
        }));
    }

    plugins.push((store) => {
        store.subscribeAction((action) => {
            if (config.features.googleTagManager) {
                analyticsActionListener(store, action);
            }
        }, {
            prepend: true,
        });
    });

    const store = createStore({
        strict,
        plugins,
        state: storeState,
        getters: storeGetters,
        mutations: storeMutations,
        actions: storeActions,
        modules: storeModules,
    });

    if (initialState) {
        store.replaceState(initialState);
    }

    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
        const stateSize = JSON.stringify(store.state).length;
        if (stateSize > STATE_SIZE_THRESHOLD_ERROR) {
            logger.error(
                `Initial Vuex store state size (${stateSize}b) is larger then ` +
                `the allowed threshold of ${STATE_SIZE_THRESHOLD_ERROR}b`,
            );
        } else if (stateSize > STATE_SIZE_THRESHOLD_WARN) {
            logger.warn(
                `Warning: Initial Vuex store state size (${stateSize}b) is larger ` +
                `then the allowed warning threshold of ${STATE_SIZE_THRESHOLD_WARN}b`,
            );
        } else {
            const prettySize = `${Math.round(stateSize / 1000)}kb`;
            logger.debug(`Initial state size is ${prettySize}`);
        }
    }

    return store;
}
