<template>
    <CoreHeader :modalOptions="navL1MenuModal">
        <template #search>
            <SearchBar />
        </template>
        <template #logo>
            <CommonHeaderLogo />
        </template>
        <template #utils>
            <HeaderUtilities />
        </template>
    </CoreHeader>
</template>

<script>
import { MODALS } from '~modules/modals/js/modal-constants';

import CoreHeader from '~coreModules/header/components/CoreHeader.vue';

import SearchBar from '~modules/search/components/SearchBar.vue';
import HeaderUtilities from '~modules/header/components/HeaderUtilities.vue';
import CommonHeaderLogo from '~modules/header/components/CommonHeaderLogo.vue';

export default {
    name: 'CommonHeader',
    components: {
        CoreHeader,
        SearchBar,
        HeaderUtilities,
        CommonHeaderLogo,
    },
    data: () => ({
        navL1MenuModal: MODALS.navL1Menu,
    }),
};
</script>

<style lang="scss">
.c-header {
    &__search-container {
        display: none;

        @include breakpoint(large) {
            display: inline-block;
        }
    }

    &__menu-label-text {
        margin-left: $nu-spacer-1;

        &--desktop {
            display: none;

            @include breakpoint(large) {
                display: block;
            }
        }

        &--mobile {
            display: block;

            @include breakpoint(large) {
                display: none;
            }
        }
    }
}
</style>
