<i18n>
[
    "global__myAccount",
]
</i18n>

<template>
    <div class="c-header-utils">
        <BaseLink
            v-for="(link, index) in topLinks"
            :key="`${link.linkPath}-${index}`"
            :to="link.linkPath"
            :target="link.openInNewWindow ? '_blank' : null"
            class="c-header-utils__link"
            @click="() => trackNavClick(link.linkPath)"
        >
            <span class="o-text--link">{{ link.linkTitle }}</span>
        </BaseLink>

        <div ref="accountIcon" class="c-header-utils__account">
            <BaseTooltip
                :trigger="tooltipTrigger"
                :tooltipVerticalMargin="0"
                :tooltipIconClick="handleUnauthenticatedClick"
                :ariaLabel="$t('global__myAccount')"
            >
                <template #launcher>
                    <div
                        class="o-flex-center c-header-utils__launcher"
                        @focus="triggerAnimation('accountIcon', true)"
                        @mouseenter="triggerAnimation('accountIcon', true)"
                    >
                        <BaseIcon
                            icon="account--my-account"
                            color="primary"
                            size="24px"
                        />
                    </div>
                </template>
                <template #body>
                    <HeaderUnauthenticatedMenu class="c-header-utils__menu" />
                </template>
            </BaseTooltip>
        </div>

        <BaseLink
            :to="anonymousHeaderCTA.linkPath"
            :aria-label="anonymousHeaderCTA.title"
            class="c-header-utils__anonymous-cta"
            @click="() => trackNavClick(anonymousHeaderCTA.linkPath)"
        >
            <BaseHeaderButton>{{ anonymousHeaderCTA.linkTitle }}</BaseHeaderButton>
        </BaseLink>
    </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { MODALS_MODULE_NAME, LAUNCH_MODAL } from '~coreModules/modals/js/modals-store';
import { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';
import { NAVIGATION_CLICKED } from '~coreModules/core/js/global-event-constants';

import { MODALS } from '~modules/modals/js/modal-constants';

import BaseTooltip from '~coreModules/core/components/ui/tooltips/BaseTooltip.vue';
import HeaderUnauthenticatedMenu from '~modules/header/components/HeaderUnauthenticatedMenu.vue';
import BaseHeaderButton from '~coreModules/header/components/BaseHeaderButton.vue';

export default {
    name: 'HeaderUtilities',
    components: {
        BaseTooltip,
        HeaderUnauthenticatedMenu,
        BaseHeaderButton,
    },
    computed: {
        ...mapGetters(NAVIGATION_MODULE_NAME, ['topLinks', 'anonymousHeaderCTA']),
        tooltipTrigger() {
            return this.$mediaQueries.isLargish ? 'hover' : 'programmatic';
        },
    },
    methods: {
        ...mapActions(MODALS_MODULE_NAME, {
            launchModal: LAUNCH_MODAL,
        }),
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        handleUnauthenticatedClick() {
            if (!this.$mediaQueries.isLargish) {
                this.launchModal(MODALS.unauthenticatedMenu);
            } else {
                window.location.href = '/rent/login/phone';
            }
        },
        triggerAnimation(targetRef, isMouseEnterEvent = false) {
            // prevent the "hover" animation on mobile, but still allow trigger animation
            if (
                (!isMouseEnterEvent || (isMouseEnterEvent && this.$mediaQueries.isLargish)) &&
                    ['accountIcon'].includes(targetRef)
            ) {
                const getExpression = `$refs.${targetRef}${this.$refs[targetRef] instanceof HTMLElement ? '' : '.$el'}`;

                const { classList } = get(this, getExpression);
                const cssClass = 'is-animating';

                if (!classList.contains(cssClass)) {
                    classList.add(cssClass);

                    setTimeout(() => {
                        this.$nextTick(() => classList.remove(cssClass));
                    }, 700);
                }
            }
        },
        trackNavClick(path) {
            this.trackGlobalEvent({
                type: NAVIGATION_CLICKED,
                data: {
                    label: path,
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .c-header-utils {
        align-items: center;
        justify-content: space-between;

        & {
            display: inline-flex;
        }

        &__account {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
                margin-right: $nu-spacer-1pt5;
            }
        }

        &__link {
            justify-content: center;
            align-items: center;
        }

        &__account {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .c-tooltip {
                &__popover {
                    width: 360px;
                }

                &__content {
                    padding-top: 0;
                    box-shadow: none;

                    &::before {
                        display: none;
                    }
                }

                &__body {
                    padding: 0
                }
            }

            .c-icon {
                transform-origin: center bottom;
            }

            &.is-animating {
                .c-icon {
                    animation-name: accountHover;
                    animation-duration: 700ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease;
                }
            }

            @keyframes accountHover {
                0%   { transform: scale(1)      translateY(0);    }
                27%  { transform: scale(1, 0.9) translateY(0);    }
                45%  { transform: scale(1, 1.1) translateY(-4px); }
                78%  { transform: scale(1, .9)  translateY(0);    }
                100% { transform: scale(1)      translateY(0);    }
            }
        }

        &__launcher {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
            }
        }

        &__menu {
            border-top: 1px solid $nu-gray--light;
        }

        &__link {
            margin-right: $nu-spacer-3;
            display: none;

            @include breakpoint(large) {
                display: inline-flex;

                &:nth-last-child(1 of &) {
                    margin-right: $nu-spacer-1pt5;
                }
            }
        }

        &__anonymous-cta {
            margin: 0 $nu-spacer-1;

            @include breakpoint(medium) {
                margin: 0;
            }
        }
    }
</style>
