import { defineAsyncComponent, markRaw } from 'vue';

import {
    GLOBAL_MODALS,
    getGlobalModalHashEvents,
    GLOBAL_MODAL_QUERY_PROPS,
} from '~coreModules/modals/js/modal-constants';

import { EMAIL_OPT_IN_LOCATIONS } from '~modules/core/js/constants';

// Whitelisted query params which can be passed to a modal's props when launching via a hash-event
// should start with 'modal-' what comes after will be the prop name
// if the prop is not defined on the modal component, it will be ignored
// ex: ?modal-skuId=abc will populate a modals skuId prop as abc
export const MODAL_QUERY_PROPS = {
    ...GLOBAL_MODAL_QUERY_PROPS,
};

export const MODALS = {
    ...GLOBAL_MODALS,
    anonymousEmailSubscribe: {
        ...GLOBAL_MODALS.anonymousEmailSubscribe,
        props: {
            isModal: true,
            optInLocation: EMAIL_OPT_IN_LOCATIONS.MODAL,
        },
    },
    closetJoinModal: {
        ...GLOBAL_MODALS.joinModal,
        id: 'closet-join-modal',
        path: '/closet-join-modal',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetJoinModal" */
                '~modules/modals/components/ClosetJoinModal.vue'))),
    },
    navL1Menu: {
        id: 'navigation-menu-l1',
        path: '/navigation',
        analytics: {
            pageCategory: 'navigation',
            pageType: 'navigation',
        },
        title: 'Nuuly',
        titleImage: 'logo.svg',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "NavigationMenuL1" */
                '~modules/nav/components/NavigationMenu.vue'))),
        isFullBleedContent: true,
        focusFirstElOnLoad: false,
        styleType: 'LEFT',
        isLight: false,
    },
    navL2Menu: {
        id: 'navigation-menu-l2',
        path: '/navigation/l2',
        analytics: {
            pageCategory: 'navigation',
            pageType: 'navigation',
        },
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "NavigationMenuL2" */
                '~coreModules/nav/components/NavigationLinksMenu.vue'))),
        isFullBleedContent: true,
        isLight: false,
        styleType: 'LEFT',
    },
    unauthenticatedMenu: {
        id: 'unauthenticated-menu',
        path: '/unauthenticated-menu',
        title: 'header__account--label',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "headerUnauthenticatedMenu" */
                '~modules/header/components/HeaderUnauthenticatedMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'RIGHT',
        isLight: false,
        focusFirstElOnLoad: false,
    },
};

/* Modals which can be triggered via a hash in the URL */
export function getModalHashEvents(featureConfig) {
    return {
        ...getGlobalModalHashEvents(featureConfig),
        [`#${GLOBAL_MODALS.exampleModal.id}`]: {
            ...GLOBAL_MODALS.exampleModal,
            props: {
                exampleModalProp: 'COMMON',
            },
        },
        [`#${MODALS.anonymousEmailSubscribe.id}`]: MODALS.anonymousEmailSubscribe,
    };
}
