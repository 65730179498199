import { merge } from 'lodash-es';
import createProfileStore from '~coreModules/profile/js/profile-store';

export { PROFILE_MODULE_NAME, GET_PROFILE_DATA } from '~coreModules/profile/js/profile-store';

export default function createCommonProfileStore() {
    return merge(createProfileStore(), {
        state: {

        },

        /* eslint-disable no-param-reassign */
        mutations: {
        },

        /* eslint-enable no-param-reassign */
        actions: {

        },

        getters: {

        },
    });
}
