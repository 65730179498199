import { merge } from 'lodash-es';

import createSiteSettings from '~coreModules/site-settings/js/site-settings-store';

export { SITE_SETTINGS_MODULE_NAME } from '~coreModules/site-settings/js/site-settings-store';

export default function createCommonSiteSettings(logger, config) {
    return merge(createSiteSettings(logger, config), {
        getters: {
            // NU_FEATURE: COMMON_HOMEPAGE_V2
            // https://urbnit.atlassian.net/browse/TYP-26324
            commonHomepageV2(state) {
                return state.contentfulAppConfig?.commonHomepageV2 || false;
            },
            /* query params we allow to be passed through to our catalog service */
            catalogFilterWhitelist(state) {
                return state.contentfulAppConfig?.attributeGroups || [];
            },
        },
    });
}
