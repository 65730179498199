import * as contentfulConstants from '~coreModules/contentful/js/contentful-constants';

export const NEWS_HOMEPAGE_ARTICLE_PAGE_SIZE = 20;
export const NEWS_TRAY_PAGE_SIZE = 3;

export const RECENT_ORDER_TRAY_DESKTOP_SLIDE_SIZE = 6;

export const CONTENT_TYPES = {
    ...contentfulConstants.CONTENT_TYPES,
    NEWS_ARTICLE: 'articleDetail',
    NEWS_ARTICLE_SUMMARY: 'articleSummary',
    NEWS_HOME: 'newsCenterHome',
};

export const CONTENT_SLUGS = {
    ...contentfulConstants.CONTENT_SLUGS,
    NEWS_HOME: 'news-center-home',
    HOMEPAGE_SLUG: 'anonymous-common-homepage',
    // NU_FEATURE: COMMON_HOMEPAGE_V2
    // https://urbnit.atlassian.net/browse/TYP-26324
    HOMEPAGE_SLUG_V2: 'new-homepage-anonymous',
    THRIFT_CATCH_ALL: 'thrift-catch-all-page',
};

export const REALTIME_SLUGS = [
    ...contentfulConstants.GLOBAL_REAL_TIME_SLUGS,
    'header__account--label',
    'meta__sharedHomepage--description',
    'meta__sharedHomepage--title',
    'meta__default--title',
    'meta__fallback--title',
    'meta__newsCenter--description',
    'meta__newsCenter--ogTitle',
    'meta__newsCenter--title',
    'sharedHomepage__brandStoryRow--textAndIllustrations',
    'sharedHomepage__leftButton--label',
    'sharedHomepage__rightButton--label',
];

export const PRODUCT_TRAY_TYPES = {
    recommendation: 'recommendation',
    catalogV2: 'v2Catalog',
};

export const {
    colorMap,
    CONTENTFUL_MODULE_TYPES,
    CONDITIONAL_CONTENTFUL_MODULE_TYPES,
    CONTENTFUL_CUSTOM_MODULE_TYPES,
    MAX_CONTENTFUL_ROW_COLUMN_OFFSET,
    EDITORIAL_TEXT_BODY_STYLES,
    PRODUCT_TRAY_TRAY_STYLES,
} = contentfulConstants;
