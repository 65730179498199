import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';
import { genericRouteNames, catchAllRouteData, APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';

const appPrefix = APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.common];

export const commonRouteNames = {
    home: 'home',
    news: {
        base: 'news',
        slug: 'news.slug',
    },
    legal: {
        slug: 'legal.slug',
    },
    unsubscribe: 'unsubscribe',
    thriftCatchAll: 'thriftCatchAll',
};
/* eslint-disable */

const homepageRouteData = {
    path: appPrefix,
    name: genericRouteNames.home,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "commonHomepage" */
        '~routes/homepage/components/CommonHomepageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'home-shared',
            pageType: 'home',
        },
    },
};

const newsRouteData = [{
    path: `${appPrefix}news`,
    name: commonRouteNames.news.base,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "NewsHomepage" */
        '~routes/news/components/NewsHomeContainer.vue'),
    meta: {
        analytics: {
            pageType: 'news',
            pageCategory: 'news-homepage',
        },
    },
}, {
    path: `${appPrefix}news/:slug`,
    name: commonRouteNames.news.slug,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "NewsDetailContainer" */
        '~routes/news/components/NewsDetailContainer.vue'),
    meta: {
        analytics: {
            pageType: 'news',
            pageCategory: 'news-detail',
        },
    },
}];

const legalRouteData = {
    path: `${appPrefix}legal/:slug`,
    name: commonRouteNames.legal.slug,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "DiscoverPage" */
        '~modules/discover/components/DiscoverPageContainer.vue'),
    meta: {
        analytics: {
            pageCategory: 'legal',
            pageType: 'legal',
        },
        isLegalPage: true,
    },
};

const unsubscribeRouteData = {
    path: `${appPrefix}unsubscribe`,
    name: commonRouteNames.unsubscribe,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "AnonymousUnsubscribe" */
        '~routes/unsubscribe/components/AnonymousUnsubscribe.vue'),
    meta: {
        ignoreBaseLayout: true,
        analytics: {
            pageCategory: 'unsubscribe',
            pageType: 'unsubscribe',
        },
    },
};

const thriftCatchAllRouteData = {
    path: `${appPrefix}thrift`,
    name: commonRouteNames.thriftCatchAll,
    component: /* istanbul ignore next */ () => import(
        /* webpackChunkName: "thriftCatchAllPage" */
        '~routes/thrift/components/ThriftCatchAllPage.vue'),
    meta: {
        ignoreBaseLayout: true,
        analytics: {
            pageCategory: 'content',
            pageType: 'content',
        },
    },
};

/* eslint-enable */

export const commonRouteData = [
    homepageRouteData,
    ...newsRouteData,
    legalRouteData,
    unsubscribeRouteData,
    thriftCatchAllRouteData,
    // IMPORTANT - The object below must be last in the list of routes passed to vue-router!
    {
        ...catchAllRouteData,
        component: /* istanbul ignore next */ () => import(
            /* webpackChunkName: "DiscoverPage" */
            '~modules/discover/components/DiscoverPageContainer.vue'),
    },
];
