<template>
    <CoreNotificationManager
        :allowNotifications="allowNotifications"
        :modalHashEvents="modalHashEvents"
        :iconComponent="BaseIcon"
    />
</template>

<script>
import { markRaw } from 'vue';
import { getModalHashEvents } from '~modules/modals/js/modal-constants';

import CoreNotificationManager from '~coreModules/notifications/components/CoreNotificationManager.vue';

import BaseIcon from '~coreModules/core/components/ui/BaseIcon.vue';

export default {
    name: 'NotificationManager',
    components: {
        CoreNotificationManager,
    },
    props: {
        allowNotifications: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const featureConfig = this.$runtimeConfig.features;

        return {
            modalHashEvents: getModalHashEvents(featureConfig),
            BaseIcon: markRaw(BaseIcon),
        };
    },
};

</script>

<style lang="scss">

</style>
