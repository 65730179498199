<i18n>
[
    "global__close",
]
</i18n>

<template>
    <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
    <Transition
        :name="currentModalTransitionName"
        appear
        @after-enter="afterEnter"
        @before-leave="modalClosed"
        @after-leave="modalClosed"
    >
        <div v-if="isOpen" class="o-modal-overlay" @click="popModals">
            <!-- eslint-enable -->
            <div
                ref="modalOuter"
                :aria-label="visibleModalOptions.title"
                aria-modal="true"
                role="dialog"
                class="o-modal__outer"
                :class="outerModalClasses"
                @click.stop
            >
                <div ref="modalTitleBarRef" :class="headerClasses" class="o-modal__header">
                    <div class="o-modal__header-button-container">
                        <div
                            v-if="visibleModalOptions.showPreviousButton"
                            v-tab-focus="popModal"
                            class="o-modal__prev is-stacked"
                        >
                            <BaseIcon
                                icon="global--chevron"
                                size="16px"
                                color="primary"
                                orientation="left"
                            />
                        </div>
                    </div>
                    <div class="o-modal__header-title-container">
                        <template
                            v-if="visibleModalOptions.showTitle"
                        >
                            <!-- eslint-disable vue/no-v-html -->
                            <div
                                v-if="!!visibleModalOptions.titleImage"
                                v-tab-focus="() => goToHomepage()"
                                :tabindex="isLogoImage ? '0' : '-1'"
                                :class="{
                                    'o-modal__header-image': true,
                                    'o-modal__header-image__logo': isLogoImage,
                                }"
                                v-html="visibleModalOptions.titleImage"
                            >
                            </div>
                            <!-- eslint-enable -->
                            <span v-else-if="visibleModalOptions.title">
                                {{ visibleModalOptions.title }}
                            </span>
                        </template>
                    </div>
                    <div class="o-modal__header-button-container">
                        <div
                            v-tab-focus="popModals"
                            :aria-label="$t('global__close')"
                            class="o-modal__close"
                        >
                            <BaseIcon
                                icon="global--close"
                                size="16px"
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
                <TransitionGroup
                    name="modal-contents"
                    tag="div"
                    class="o-modal"
                    @after-enter="afterModalSlideEnter"
                >
                    <div
                        v-for="modal in activeModals"
                        :ref="setModalsRefs"
                        :key="modal.key"
                        :class="{
                            'is-shown': modal.isShown,
                            'is-stacked': isStacked,
                            'has-padding': !visibleModalOptions.isFullBleedContent,
                            'is-light': visibleModalOptions.isLight,
                            'is-content-centered': visibleModalOptions.isContentCentered,
                            'no-padding': !visibleModalOptions.hasInnerPadding,
                        }"
                        :style="`--titleBarHeight: ${titleBarHeight};`"
                        class="o-modal__inner"
                    >
                        <Component :is="modal.component" v-bind="modal.props" />
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </Transition>
</template>

<script>

import { get, last } from 'lodash-es';
import { mapMutations, mapActions, mapGetters } from 'vuex';

import {
    MODALS_MODULE_NAME,
    POP_MODALS,
    POP_MODAL,
} from '~coreModules/modals/js/modals-store';

import { genericRouteNames } from '~coreModules/core/js/router-constants';

import { FOCUSABLE_SELECTORS, KEYCODES } from '~coreModules/core/js/constants';
import { dynamicSVGRequire } from '~coreModules/core/js/svg-utils';

export default {
    name: 'UrbnModalSingleton',
    components: {},
    props: {
        contentEl: {
            type: process.env.VUE_ENV === 'client' ? HTMLElement : undefined,
            default: null,
        },
        scrollTopWithoutHeader: {
            type: Number,
            default: null,
        },
    },
    emits: ['closed', 'opened'],
    data() {
        return {
            priorFocusedElement: null,
            titleBarHeight: '46px',
            modalRefs: [],
            currentModalTransitionName: '',
        };
    },
    computed: {
        ...mapGetters(MODALS_MODULE_NAME, [
            'modals',
            'isOpen',
        ]),
        activeModals() {
            return this.modals.map((modalOpts, idx) => ({
                ...modalOpts,
                isShown: idx === this.modals.length - 1,
            }));
        },
        hasModals() {
            return this.activeModals.length > 0;
        },
        isStacked() {
            return this.activeModals.length > 1;
        },
        isLogoImage() {
            return this.visibleModalOptions.titleImage === 'logo.svg';
        },
        visibleModal() {
            return this.modals[this.modals.length - 1];
        },
        visibleModalOptions() {
            if (!this.visibleModal) {
                return {};
            }

            const { title, titleImage } = this.visibleModal;

            const safeTitleImage = !titleImage ? '' : dynamicSVGRequire(titleImage, this.$logger) ||
                dynamicSVGRequire(titleImage, this.$logger, false);

            const visibleModalOptions = {
                title: this.$t(title),
                titleImage: safeTitleImage,
                showTitle: get(this.visibleModal, 'showTitle', true),
                isFullBleedContent: get(this.visibleModal, 'isFullBleedContent', false),
                styleType: get(this.visibleModal, 'styleType', ''),
                focusFirstElOnLoad: get(this.visibleModal, 'focusFirstElOnLoad', true),
                isLight: get(this.visibleModal, 'isLight', true),
                isContentCentered: get(this.visibleModal, 'isContentCentered', false),
                headerClass: get(this.visibleModal, 'headerClass', ''),
                hasInnerPadding: get(this.visibleModal, 'hasInnerPadding', true),
                showPreviousButton: this.isStacked,
            };

            return visibleModalOptions;
        },
        outerModalClasses() {
            const styleTypeClass = `is-styletype-${get(this.visibleModal, 'styleType', '').toLowerCase()}`;

            return {
                [styleTypeClass]: true,
                'is-light': this.visibleModalOptions.isLight,
                'has-padding': !this.visibleModalOptions.isFullBleedContent,
            };
        },
        modalTransitionName() {
            const activeStyleType = get(this.visibleModal, 'styleType', '');

            return `modal-container-${activeStyleType.toLowerCase()}`;
        },
        headerClasses() {
            return {
                ...this.visibleModalOptions.headerClass,
                'o-modal__header--no-title': !this.visibleModalOptions.showTitle,
            };
        },
    },
    watch: {
        modalTransitionName(newVal) {
            if (newVal !== 'modal-container-') {
                this.currentModalTransitionName = newVal;
            }
        },
    },
    beforeUpdate() {
        this.modalRefs = [];
    },
    methods: {
        ...mapMutations(MODALS_MODULE_NAME, { popModal: POP_MODAL }),
        ...mapActions(MODALS_MODULE_NAME, {
            popModals: POP_MODALS,
        }),
        setModalsRefs(el) {
            if (el) {
                this.modalRefs.push(el);
            }
        },
        goToHomepage() {
            if (this.isLogoImage) {
                this.onEscape(true);
                this.$router.push({ name: genericRouteNames.home });
            }
        },
        onKeyDown(e) {
            if (e.keyCode === KEYCODES.escape) {
                this.onEscape();
            } else if (e.keyCode === KEYCODES.tab) {
                this.onTab(e);
            }
        },
        onEscape(omitPageviewEvent = false) {
            this.popModals({ omitPageviewEvent });
        },
        onTab(e) {
            const modalEl = this.getActiveModalEl();
            if (!modalEl) {
                this.$logger.warn('Modal element not found for focus trap');
                return;
            }

            const filterHiddenEls = els => Array.from(els).filter(element => !!element.offsetParent);

            const titleBarFocusableEls = filterHiddenEls(
                this.$refs.modalTitleBarRef.querySelectorAll(FOCUSABLE_SELECTORS),
            );
            const modalSlideFocusableEls = filterHiddenEls(
                modalEl.querySelectorAll(FOCUSABLE_SELECTORS),
            );

            const modalFocusableEls = [...titleBarFocusableEls, ...modalSlideFocusableEls];

            if (modalFocusableEls.length === 0) {
                this.$logger.warn('No focusable elements in modal');
                return;
            }

            if (modalFocusableEls.length === 1) {
                this.$logger.warn('Only one focusable element in modal; maintaining focus there');
                modalFocusableEls[0].focus();
                e.preventDefault();
                return;
            }

            const focusedItem = this.getCurrentFocusedEl();
            const focusedItemIndex = modalFocusableEls.indexOf(focusedItem);

            const focusTitleBar = () => {
                this.$logger.debug('Looping tab focus to modal titleBar');
                titleBarFocusableEls[0].focus();
            };

            const focusModalContentLastElement = () => {
                this.$logger.debug('Looping shift-tab focus back to the last element');
                modalFocusableEls[modalFocusableEls.length - 1].focus();
            };

            // User is tabbing backwards, and is on the first focusable item in the title bar
            // Set focus to the last focusable item in the active modal slide
            if (e.shiftKey && focusedItemIndex === 0) {
                focusModalContentLastElement();

            // User is tabbing backwards, and their focus is outside of the modal
            // Set focus to the last element of the modal
            } else if (e.shiftKey && focusedItemIndex < 0) {
                focusModalContentLastElement();

            // User is tabbing forward, and is on the last focusable item in modal
            // Set focus to the title bar
            } else if (!e.shiftKey && focusedItemIndex === modalFocusableEls.length - 1) {
                focusTitleBar();

            // User is tabbing forward, and is focused on an element outside of the active modal,
            // Set focus to the title bar
            } else if (!e.shiftKey && focusedItemIndex < 0) {
                focusTitleBar();

            // User is just tabbing forward; set focus to the next focusable element
            // Must be done programmatically to circumvent Safari skipping <a> tags on tab by default
            } else if (!e.shiftKey) {
                modalFocusableEls[focusedItemIndex + 1].focus();

            // User is just tabbing backwards; set focus to the previous focusable element
            // Done programmatically for the same reason as above
            } else {
                modalFocusableEls[focusedItemIndex - 1].focus();
            }

            e.preventDefault();
        },
        getActiveModalEl() {
            return last(this.modalRefs);
        },
        getCurrentFocusedEl() {
            if (document.activeElement && document.activeElement.nodeName !== 'svg') {
                return document.activeElement;
            }
            return null;
        },
        setFocusToFirstChild() {
            if (!this.visibleModalOptions.focusFirstElOnLoad) return;

            const modalEl = this.getActiveModalEl();
            if (!modalEl) {
                this.$logger.warn('Modal element not found for autofocus');
                return;
            }
            // get list of all children elements in given object
            const firstFocusableEl = modalEl.querySelector(FOCUSABLE_SELECTORS);
            if (firstFocusableEl) {
                this.$logger.debug('Setting focus to', firstFocusableEl);
                firstFocusableEl.focus();
            } else {
                this.$logger.debug('Setting focus to outer element', modalEl);
                modalEl.focus();
            }
        },
        setTitleBarHeight() {
            this.titleBarHeight = `${this.$refs.modalTitleBarRef.offsetHeight}px`;
        },
        contentFocusInListener() {
            this.$logger.debug('Captured focusin outside modal - moving focus to inside modal');
            this.setFocusToFirstChild();
        },
        modalOpened() {
            this.$logger.debug('Modal Opened');

            // Listen for Excape/Tab key presses on the entire document
            document.addEventListener('keydown', this.onKeyDown);

            // Redirect the focus back into the modal if the user tries to
            // focus outside the modal.  I.e., tabbing from the URL bar
            if (this.contentEl) {
                this.contentEl.addEventListener('focusin', this.contentFocusInListener);
            }

            // Track the element focused prior to opening the modal
            this.priorFocusedElement = this.getCurrentFocusedEl();

            // Auto-focus the first element inside the modal
            this.setFocusToFirstChild();

            this.setTitleBarHeight();

            this.$emit('opened', true);
        },
        modalClosed() {
            this.$logger.debug('Modal Closed');

            if (this.contentEl) {
                this.contentEl.removeEventListener('focusin', this.contentFocusInListener);
            }
            document.removeEventListener('keydown', this.onKeyDown);

            // scroll back to the position where the user was before opening the modal
            if (this.scrollTopWithoutHeader) {
                window.scrollTo(0, this.scrollTopWithoutHeader);
            }

            if (this.priorFocusedElement) {
                setTimeout(() => {
                    this.priorFocusedElement?.focus?.();
                    this.priorFocusedElement = null;
                }, 100);
            }

            this.$emit('closed', true);
        },
        afterEnter() {
            this.modalOpened();

            // Note: We do not handle closed modal in this method because by the
            // time the transition finishes, this modal has been destroyed so the
            // event listener has been removed.  We handle them in destroyed to
            // ensure they always fire
        },
        /* Scroll modals to top after they go out of view */
        afterModalSlideEnter() {
            const previousModalSlide = this.modalRefs[this.modalRefs.length - 2];

            if (previousModalSlide) {
                previousModalSlide.classList.add('js-height-reset');

                setTimeout(() => {
                    previousModalSlide.classList.remove('js-height-reset');
                }, 50);

                this.setFocusToFirstChild();
            }
        },
        dynamicSVGRequire,
    },
};
</script>

<style lang="scss">
    $modal-transition-duration: 400ms;
    $modal-content-shift: 100%;

    .js-height-reset {
        height: unset !important;
    }

    .o-modal-overlay {
        @include overlay();
        z-index: map-get($zindex, modal-overlay);
    }

    .o-modal {
        position: relative;
        height: 100%;

        &__header,
        &__prev,
        &__close,
        &__header-button-container,
        &__header-title-container {
            display: inline-flex;
            align-items: center;
            outline-offset: -4px;
        }

        &__header {
            background-color: $nu-white;
            justify-content: space-between;
            height: 48px;
            width: 100%;
            border-bottom: 1px solid $nu-gray--light;

            &--no-title {
                background-color: unset;
            }
        }

        $header-btn-clickable: 48px;

        &__prev,
        &__close {
            cursor: pointer;
            justify-content: center;
            height: $header-btn-clickable;
            width: $header-btn-clickable;
        }

        &__header-button-container,
        &__header-title-container {
            height: 100%;
        }

        &__header-button-container {
            width: $header-btn-clickable;
        }

        &__header-title-container {
            width: calc(100% - #{$header-btn-clickable});
            justify-content: center;
        }

        &__header-image {
            height: 100%;
            display: flex;
            align-items: center;
            padding-inline: $nu-spacer-2;
            outline-offset: -4px;

            svg {
                height: 16px;
                fill: $nu-primary;
            }

            &__logo {
                cursor: pointer;
            }
        }

        &__outer {
            display: flex;
            flex-direction: column;

            position: fixed;
            top: 0;
            left: 0;
            z-index: map-get($zindex, modal);
            overflow: hidden;
            // Added manually because autoprefixer doesn't add '-webkit-overflow-scrolling'
            -webkit-overflow-scrolling: touch;
            width: 100%;
            height: 100%;
            background-color: $nu-gray--light;

            &.is-light:not(.is-styletype-bottom) {
                background-color: $nu-white;
            }

            &.is-styletype-bottom,
            &.is-styletype-bottom-extra-short,
            &.is-styletype-bottom-short,
            &.is-styletype-bottom-fullscreen {
                top: unset;
                right: 0;
                bottom: 0;
            }

            &.is-styletype-bottom {
                height: 85%;
            }

            &.is-styletype-bottom-extra-short {
                height: 40%;
            }

            &.is-styletype-bottom-short {
                height: 65%;
            }

            &.is-styletype-right, &.is-styletype-left {
                left: unset;
                height: var(--vh100);
                width: 90%;
                box-shadow: 0 3px 3.5px 0 rgba(0, 0, 0, 0.25);

                @include breakpoint(medium) {
                    width: 424px;
                }
            }

            &.is-styletype-right {
                right: 0;
            }

            &.is-styletype-center {
                @include breakpoint(medium) {
                    top: 20%;
                    left: 20%;
                    width: 60%;
                    height: 60%;
                }
            }

            &.is-styletype-top {
                top: 0;
                height: 60%;
            }
        }

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $nu-gray--light;

            .is-styletype-top & {
                height: 100%;
                overflow: hidden;
            }

            &.is-light:not(.is-styletype-bottom) {
                background-color: $nu-white;
            }

            transform: translate3d(-$modal-content-shift, 0, 0);
            opacity: 0;
            transition:
                opacity $modal-transition-duration ease,
                transform $modal-transition-duration ease;
            overflow-y: scroll;

            &.is-shown {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }

            &.has-padding {
                padding: $nu-spacer-3;
            }

            .is-styletype-center & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});
            }

            .is-styletype-bottom &,
            .is-styletype-bottom-short &,
            .is-styletype-bottom-extra-short &,
            .is-styletype-fullscreen &,
            .is-styletype-bottom-fullscreen & {
                background-color: $nu-secondary;
            }

            .is-styletype-bottom & {
                height: calc(calc(var(--vh) * 85) - #{var(--titleBarHeight)});

                &:not(.no-padding) {
                    padding: $nu-spacer-6 $nu-spacer-2 $nu-spacer-8;
                    overflow-x: hidden;

                    @include breakpoint(medium) {
                        padding-left: $nu-spacer-4;
                        padding-right: $nu-spacer-4;
                    }

                    @include breakpoint(large) {
                        padding: $nu-spacer-8 $nu-spacer-8 $nu-spacer-16;
                    }
                }
            }

            .is-styletype-bottom-fullscreen & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});

                &:not(.no-padding) {
                    padding: $nu-spacer-6 $nu-spacer-2 $nu-spacer-8;
                    overflow-x: hidden;

                    @include breakpoint(medium) {
                        padding-left: $nu-spacer-4;
                        padding-right: $nu-spacer-4;
                    }

                    @include breakpoint(large) {
                        padding: $nu-spacer-8 $nu-spacer-8 $nu-spacer-16;
                    }
                }
            }

            .is-styletype-bottom-short & {
                height: calc(calc(var(--vh) * 65) - #{var(--titleBarHeight)});
            }

            .is-styletype-bottom-extra-short & {
                height: calc(calc(var(--vh) * 40) - #{var(--titleBarHeight)});
            }

            .is-styletype-right &, .is-styletype-left & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});
            }

            .is-styletype-fullscreen & {
                height: 100%;
            }

            &.is-content-centered {
                align-items: center;
                display: flex;
                height: 100%;

                & > * {
                    flex: 1;
                }
            }
        }
    }

    // Modal Container Animation (CENTER, FULLSCREEN)
    .modal-container-center, .modal-container-fullscreen {
        &-enter-from,
        &-leave-to {
            opacity: 0;
            transform: scale(1.1);
        }

        &-enter-active,
        &-leave-active {
            transition:
                opacity $modal-transition-duration ease,
                transform $modal-transition-duration ease;
        }
    }

    // Modal Container Animation (RIGHT, BOTTOM, LEFT)
    .modal-container-right,
    .modal-container-bottom,
    .modal-container-bottom-extra-short,
    .modal-container-bottom-short,
    .modal-container-bottom-fullscreen,
    .modal-container-top,
    .modal-container-left {
        &-enter-from,
        &-leave-to {
            &.o-modal-overlay {
                background-color: $transparent;
            }
        }

        &-enter-to,
        &-leave-from {
            &.o-modal-overlay {
                background-color: $modal-overlay;
            }
        }

        &-enter-active,
        &-leave-active {
            &.o-modal-overlay {
                transition: background $modal-transition-duration ease;
            }

            .o-modal__outer {
                transition: transform $modal-transition-duration ease;
            }
        }
    }

    // Modal Container Animation (RIGHT, LEFT)
    .modal-container-right, .modal-container-left {
        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    // Modal Container Animation (RIGHT)
    .modal-container-right {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translate3d(100%, 0, 0);
            }
        }
    }

    // Modal Container Animation (LEFT)
    .modal-container-left {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translate3d(-100%, 0, 0);
            }
        }
    }

    // Modal Container Animation - bottom-aligned modals
    .modal-container-bottom,
    .modal-container-bottom-extra-short,
    .modal-container-bottom-short,
    .modal-container-bottom-fullscreen {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translateY(100%);
            }
        }

        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translateY(0);
            }
        }
    }

    // Modal Container Animation - top modals
    .modal-container-top {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translateY(-100%);
            }
        }

        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translateY(0);
            }
        }
    }

    // Modal Contents Animation
    .modal-contents {
        &-enter-from.o-modal__inner.is-stacked,
        &-leave-to.o-modal__inner.is-stacked {
            transform: translate3d($modal-content-shift, 0, 0);
            opacity: 0;
        }
    }
</style>
