export const SITE_META = {
    defaultTitle: 'meta__default--title',
    fallbackTitle: 'meta__fallback--title',
    homepageDescription: 'meta__sharedHomepage--description',
    homepageOGDescription: 'meta__sharedHomepage--description',
    homepageTitle: 'meta__sharedHomepage--title',
    homepageOGTitle: 'meta__sharedHomepage--title',
    jsonLDName: 'Nuuly',
    jsonLDUrlPath: '',
};

export default SITE_META;
