<template>
    <CoreApp :contentIsDoneRendering="handleContentIsDoneRenderingChange">
        <template #header>
            <CommonHeaderV2 v-if="commonHomepageV2" />
            <CommonHeader v-else />
        </template>
        <template #footer>
            <AsyncAnonymousEmailSubscribe
                v-if="renderEmailSubscribe"
                :isLight="false"
                :optInLocation="optInLocation"
            />
            <BaseFooter
                v-if="commonHomepageV2"
                :showFooter="routeIsDoneRendering"
                :footerNav="footerNav"
                :appStoreLogoLink="appStoreLogoLink"
            >
                <BaseLink :to="rentalUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 146.9" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M77.3 17.6c12.8 0 22.5 3.8 29.1 11.5 6.6 7.6 9.8 18.9 9.8 33.7v53.4H76.7v-45c0-14.4-5.9-21.6-17.8-21.6-13 0-19.5 7.4-19.5 22.3v44.4H0V21.8h39.1v23.6c3.3-9.5 8.1-16.5 14.3-21 6.1-4.5 14.1-6.8 23.9-6.8M214.1 21.8h39.6v94.4h-39.6V96.7c-6.5 15.3-18.9 22.9-37.3 22.9-12.6 0-22.2-3.5-28.8-10.6-6.6-7-9.9-17.1-9.9-30.3v-57h39.6V69c0 7.2 1.4 12.5 4.3 15.9 2.8 3.4 7.3 5.1 13.3 5.1 12.6 0 18.9-7.3 18.9-21.8V21.8zM333.3 119.5c-19.5 0-34-4.4-43.5-13.2-9.5-8.8-14.3-20.2-14.3-34.1V21.7h39.6v45.7c0 7.2 1.5 12.5 4.4 15.9 2.9 3.4 7.5 5.1 13.9 5.1s11-1.7 13.9-5.1c2.9-3.4 4.4-8.7 4.4-15.9V21.7h39.6v50.5c0 14-4.8 25.4-14.4 34.1-9.9 8.8-24.3 13.2-43.6 13.2M413.3 0h39.4v116.2h-39.4zM558.2 21.8l-22.8 50.8-21.3-50.8H472l41.3 85.9-21.1 39.2h44.5L600 21.8z"/></svg>
                </BaseLink>
            </BaseFooter>
            <BaseFooter
                v-else
                :showFooter="routeIsDoneRendering"
                :footerNav="footerNav"
            />
        </template>

        <template v-if="!isMobileWebview" #belowContainerContents>
            <NotificationManager :allowNotifications="allowNotifications" />
        </template>
    </CoreApp>
</template>

<script>
import { mapGetters } from 'vuex';
import { markRaw, defineAsyncComponent } from 'vue';

import { MODALS_MODULE_NAME } from '~coreModules/modals/js/modals-store';

import { NAVIGATION_MODULE_NAME } from '~modules/nav/js/nav-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { APP_BASE_URLS_MAP } from '~coreModules/core/js/router-constants';
import { NUULY_BUSINESS_TYPES } from '~coreModules/core/js/constants';
import { NUULY_RENT_APP_STORE_LINK, EMAIL_OPT_IN_LOCATIONS } from '~modules/core/js/constants';

import metaTagsMixin from '~modules/core/js/seo/meta-tags';
import analyticsInitMixin from '~modules/analytics/js/analytics-init';

// Load this before importing any components, this ensures that all of our global
// classes come first in the generated app.css file, followed by
// any component-specific classes
import '~coreScss/global/global-styles.scss';
import CoreApp from '~coreModules/core/components/CoreApp.vue';
import BaseFooter from '~coreModules/footer/components/BaseFooter.vue';

import CommonHeader from '~modules/header/components/CommonHeader.vue';
import CommonHeaderV2 from '~modules/header/components/CommonHeaderV2.vue';
import NotificationManager from '~modules/notifications/components/NotificationManager.vue';

export default {
    name: 'App',
    components: {
        CoreApp,
        BaseFooter,
        CommonHeader,
        CommonHeaderV2,
        NotificationManager,
        AsyncAnonymousEmailSubscribe: markRaw(
            defineAsyncComponent(
                () => import(
                    /* webpackChunkName: "anonymousEmailSubscribe" */
                    '~coreModules/core/components/ui/AnonymousEmailSubscribe.vue'),
            ),
        ),
    },
    mixins: [metaTagsMixin, analyticsInitMixin],
    data() {
        return {
            routeIsDoneRendering: false,
            appStoreLogoLink: NUULY_RENT_APP_STORE_LINK,
            rentalUrl: APP_BASE_URLS_MAP[NUULY_BUSINESS_TYPES.rental],
            optInLocation: EMAIL_OPT_IN_LOCATIONS.FOOTER,
        };
    },
    computed: {
        ...mapGetters(['isMobileWebview', 'loggedIn']),
        ...mapGetters(NAVIGATION_MODULE_NAME, ['footerNav']),
        ...mapGetters(MODALS_MODULE_NAME, { modalIsOpen: 'isOpen' }),
        allowNotifications() {
            return !!(!this.modalIsOpen && this.routeIsDoneRendering);
        },
        // NU_FEATURE: COMMON_HOMEPAGE_V2
        // https://urbnit.atlassian.net/browse/TYP-26324
        ...mapGetters(SITE_SETTINGS_MODULE_NAME, [
            'commonHomepageV2',
        ]),
        // NU_FEATURE: COMMON_HOMEPAGE_V2
        // remove feature check
        renderEmailSubscribe() {
            return this.commonHomepageV2 && !this.loggedIn && this.routeIsDoneRendering;
        },
    },
    methods: {
        handleContentIsDoneRenderingChange(isDoneRendering) {
            this.routeIsDoneRendering = isDoneRendering;
        },
    },
};
</script>

<style lang="scss">

</style>
